@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-size: 16px;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span {
  font-family: "League Spartan", sans-serif;
  font-optical-sizing: auto;
}
